import { BackgroundImage } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';
import IMAGES from '@akin/core-lib/banners';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.white,
    minHeight: '100vh',
  },
  formContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 35,
    [theme.fn.smallerThan('md')]: {
      width: '100%',
      padding: 20,
    },
  },
  imageSection: {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    width: '50%',
    paddingTop: 35,
    paddingRight: 35,
    paddingBottom: 35,
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
}));

export default function AuthPageWrapper({ children }) {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.formContainer}>{children}</div>
      <div className={classes.imageSection}>
        <BackgroundImage imgSrc={IMAGES.loginBanner} borderRadius={15} />
      </div>
    </div>
  );
}
