export default function getError(fieldName, { touched, errors }) {
  const errorStatus = {
    status: false,
    msg: '',
  };

  const hasError = !!(touched[fieldName] && errors[fieldName]);

  if (hasError) {
    errorStatus.status = true;
    errorStatus.msg = errors[fieldName];
  }

  return errorStatus;
}

export function getArrayFieldError(fieldName, { touched, errors, index }) {
  const errorStatus = {
    status: false,
    msg: '',
  };

  const isTouched = touched?.[index]?.[fieldName] || false;
  const fieldError = errors?.[index]?.[fieldName]?.props?.id || null;

  const hasError = !!(isTouched && fieldError);

  if (hasError) {
    errorStatus.status = true;
    errorStatus.msg = fieldError;
  }

  return errorStatus;
}
